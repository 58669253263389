body {
    /*margin-top: 88px;*/
}

.ff-righteous {
    font-family: 'Righteous','Poppins', sans-serif;
}

.btn-sm {
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    color: #ffffff;
    height: 30px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.btn-danger:hover {
    color: #D23200 !important;
    background-color: #fff !important;
}

.btn-danger {
    background-color: #D23200 !important;
}

.profile-thumb {
    max-height: 100px;
    max-width: 100px;
}

.imgbg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 200px;
}

.profile-icon {
    max-height: 38px;
    min-height: 38px;
    max-width: 38px;
    max-width: 38px;
}

.form-label {
    font-size: 13px;
    font-weight: bold;
}

textarea.form-control {
    padding: 10px 15px;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #30235B;
    --bs-btn-border-color: #30235B;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #30235B;
    --bs-btn-disabled-border-color: #30235B;
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: #30235B !important;
}

.profile-pic-border {
    border-width: 2px !important;
    border-style: solid;
    border-color: #30235B;
    padding: 2px;
    border-radius: 18% !important;
}

.carousel-indicators {
    justify-content: start;
    margin-left: 1%;
}

    .carousel-indicators [data-bs-target] {
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-radius: 10px;
    }

.text-primary {
    color: #30235B !important;
}

.text-secondary {
    color: #616161 !important;
}

.text-danger {
    color: #D23200 !important;
}

.bg-danger {
    background-color: #D23200 !important;
}

.bg-primary {
    background-color: #30235B !important;
}

.logo {
    max-height: 60px;
}

.fs-24 {
    font-size: 20px !important;
}

.fs-20 {
    font-size: 16px !important;
}

.fs-small {
    font-size: 13px !important;
}

.fs-verysmall {
    font-size: 11px !important;
}

.text-justify {
    text-align: justify !important;
}

.loader-center {
    width: 80px;
    position: fixed;
    height: 80px;
    top: 50%;
    margin-top: -40px;
    left: 50%;
    margin-left: -40px;
}

#mainmenubar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /*right: 0;
    left: 0;*/
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid lightgrey;
    background: #ffffff;
}

.sidemenubar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    width: auto;
    border-right: 1px solid lightgrey;
    background: #ffffff;
    height: 100vh;
}

    .sidemenubar .item {
        height: 14.28vh;
        text-align: center;
    }

.btn-follow {
    min-width: 80px;
}

.sticky-column {
    top: 95px;
    position: -webkit-sticky;
    position: sticky;
}

.sticky-bottom-column {
    bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
}

.contact {
    border-radius: 5px;
    padding: 10px
}

    .contact.selected {
        background: #30235B;
        color: #fff !important;
    }

    .contact .contactname {
        color: #09132C;
        font-size: 1rem;
        font-weight: 600;
        text-transform: capitalize;
    }

    .contact.selected .contactname {
        color: #fff !important;
    }
.personalchatcont{
    height:100vh;
}

.chatpersoninfocont {
    background: rgba(48,35,91, 0.1);
    padding: 1rem;
}
    .chatpersoninfocont .personname {
        max-Width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: 600;
        color: #09132C;
    }
    .memberpost {
        max-width: 800px;
    }

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1120px;
    }
}

@media (min-width: 992px) {
    .px-lg-6 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }

    .px-lg-7 {
        padding-right: 7rem !important;
        padding-left: 7rem !important;
    }
}

@media (min-width: 769px) and (max-width: 950px) {
    .btn-follow {
        width: auto;
    }

    .logo {
        max-height: 60px;
    }

    .fs-24 {
        font-size: 20px !important;
    }

    .fs-20 {
        font-size: 16px !important;
    }

    #mainmenubar {
        height: 87px;
    }

    .btn {
        font-size: 13px;
        height: 35px;
    }
}

@media (max-width : 768px) {
    /*body {
        margin-top: 58px;
    }*/

    #mainmenubar {
        /*position: static;
        right: auto;
        top: auto;
        left: auto;
        z-index: auto;*/
        height: auto;
    }

    .profile-icon {
        max-height: 35px;
        min-height: 35px;
        max-width: 35px;
        max-width: 35px;
    }

    .menubar {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 1020;
    }


    .logo {
        max-height: 35px;
    }
}
